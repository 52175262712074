import * as React from "react";
import AssetPage from "../../components/asset-page";

const OptimismPage = () => (
  <AssetPage
    asset="Optimism"
    ticker="OP"
    description={[
      `In the rapidly evolving world of decentralized applications (dApps) and smart contracts, Ethereum's scaling challenges have been a persistent concern. Enter the Optimism Network, a layer-2 scaling solution that employs Optimistic Rollups to enhance transaction throughput and reduce associated costs on the Ethereum blockchain. The team behind Optimism has been laser-focused on alleviating Ethereum's congestion, ensuring dApps can operate efficiently and cost-effectively.`,
      `The Optimism Network represents a significant stride forward in addressing Ethereum's scalability challenges. By marrying innovative rollup technology with seamless integration capabilities and Ethereum's inherent security, Optimism is poised to shape the future trajectory of the Ethereum dApp landscape. As developers and users increasingly look for efficient, scalable, and secure solutions, Optimism stands out as a promising contender in the layer-2 space.`,
    ]}
    hideFeatureImage
    features={[
      {
        title: "Optimistic Rollups",
        text: `At the heart of Optimism's framework are Optimistic Rollups, a technique that bundles or "rolls up" multiple transactions into a single proof, which is then submitted to the Ethereum mainnet. This process not only accelerates transaction processing times but also considerably reduces gas fees, making dApp interactions more accessible and affordable for users.`,
      },
      {
        title: "Seamless Integration",
        text: "One of the standout traits of the Optimism Network is its compatibility with Ethereum's tooling and smart contracts. This means developers can port their dApps to Optimism with minimal changes, ensuring a smooth transition and reducing the barriers to adoption. Such seamless integration accelerates the adoption of layer-2 solutions within the broader Ethereum ecosystem.",
      },
      {
        title: "Decentralized Security",
        text: "The Optimism Network inherits Ethereum's robust security features. By operating as a layer on top of Ethereum, Optimism ensures that its consensus is rooted in the security guarantees of the primary Ethereum network. This approach ensures that assets and operations on Optimism are as secure as those on Ethereum, while benefiting from increased scalability.",
      },
    ]}
  />
);

export default OptimismPage;
